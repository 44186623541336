<template>
  <div>
    <b-card>
      <b-card-text class="mb-2">
        <h5>{{ $t('product information') }}</h5>
      </b-card-text>
      <label>{{ $t('product images') }}</label>
      <b-media class="mb-2">
        <template #aside>
          <div
            id="my-strictly-unique-vue-upload-multiple-image"
            style="display: flex; justify-content: center;"
          >
            <vue-upload-multiple-image
              drag-text="拖拉上傳"
              browse-text="選取檔案"
              drop-text="拖曳照片至此"
              primary-text="主照片"
              mark-is-primary-text="設為主照片"
              popup-text="主要顯示照片"
              :max-image="maxImages"
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @edit-image="editImage"
            />
          </div>
        </template>
        <!-- <h4 class="mb-1">
          {{ userData.fullName }}
        </h4> -->
      </b-media>

      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('product name')"
              label-for="product-name"
            >
              <b-form-input
                id="product-name"
                v-model="form.productName"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('product type')"
              label-for="product-type"
            >
              <b-form-input
                id="product-type"
                v-model="form.productType"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('condition')"
              label-for="product-condition"
            >
              <v-select
                v-model="form.productCondition"
                :options="collateralConditions"
                label="title"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('product brand')"
              label-for="product-brand"
            >
              <b-form-input
                id="product-brand"
                v-model="form.productBrand"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('product model')"
              label-for="product-model"
            >
              <b-form-input
                id="product-model"
                v-model="form.productModel"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('product year')"
              label-for="product-year"
            >
              <b-form-input
                id="product-year"
                v-model="form.productYear"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('location')"
              label-for="location"
            >
              <b-form-input
                id="location"
                v-model="form.location"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('product appraisal')"
              label-for="product-appraisal"
            >
              <b-input-group
                id="product-appraisal"
                prepend="$"
                class="input-group-merge"
              >
                <b-form-input
                  v-model="form.productAppraisal"
                  placeholder="10,000"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              :label="$t('other information')"
              label-for="other-information"
            >
              <b-form-textarea
                id="other-information"
                v-model="form.otherInformation"
                :placeholder="$t('other information')"
                rows="8"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <hr class="invoice-spacing">
          </b-col>
        </b-row>

        <b-card-text class="mb-2">
          <h5>{{ $t('customer and loan') }}</h5>
        </b-card-text>

        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('customer')"
              label-for="customer"
            >
              <vue-autosuggest
                v-model="form.customer"
                :suggestions="filteredOptions"
                :input-props="inputProps"
                :limit="10"
                :get-suggestion-value="getSuggestionValue"
                @selected="onSelected"
                @input="onInputChange"
              >
                <template slot-scope="{suggestion}">
                  <div class="d-flex align-items-center">
                    <b-avatar :src="suggestion.item.photoURL" />
                    <div class="detail ml-50">
                      <b-card-text class="mb-0">
                        {{ suggestion.item.basicInfo.fullName }}
                      </b-card-text>
                      <small class="text-muted">
                        {{ suggestion.item.suggest }}
                      </small>
                    </div>
                  </div>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
        </b-row>

        <div v-if="selected.id">
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('collateral date')"
                label-for="collateral-date"
              >
                <b-form-datepicker
                  id="collateral-date"
                  v-model="form.collateralDate"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('foreclosure date')"
                label-for="foreclosure-date"
              >
                <b-form-datepicker
                  id="foreclosure-date"
                  v-model="form.foreclosureDate"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="`${$t('loan amount')} ( ${form.productAppraisal} )`"
                label-for="loan-amount"
              >
                <b-input-group
                  id="loan-amount"
                  prepend="$"
                  class="input-group-merge"
                >
                  <b-form-input
                    v-model="form.loanAmount"
                    :placeholder="form.productAppraisal"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="`${$t('interest rate')} ( ${paymentAmount} ) `"
                label-for="interest-rate"
              >
                <b-input-group append="%">
                  <b-form-input
                    id="interest-rate"
                    v-model="form.interestRate"
                    placeholder="1"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('payment duration')"
                label-for="payment-duration"
              >
                <b-input-group :append="$t('days')">
                  <b-form-input
                    id="payment-duration"
                    v-model="form.paymentDuration"
                    placeholder="30"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col
            cols="12"
            class="mb-1"
          >
            <hr class="invoice-spacing">
          </b-col>
        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="handleSuibmit"
      >
        {{ !editDisabled ? $t('create') : $t('update') }}
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Reset
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BMedia, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BFormDatepicker, BFormTextarea, BAvatar, BCardText, BInputGroup,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
// import router from '@/router'
import { mapGetters } from 'vuex'
import store from '@/store'
import {
  round, multiply, divide,
} from 'mathjs'
import imageCompression from 'browser-image-compression'

/* eslint-disable no-param-reassign */

export default {
  components: {
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BMedia,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    VueAutosuggest,
    BCardText,
    BAvatar,
    vSelect,
    VueUploadMultipleImage,
  },
  data() {
    return {
      editDisabled: false,
      userData: {
        id: 45,
        fullName: 'Corrie Perot',
        company: 'Flipopia PVT LTD',
        role: 'subscriber',
        username: 'cperot18',
        country: 'China',
        contact: '(659) 385-6808',
        email: 'cperot18@goo.ne.jp',
        currentPlan: 'team',
        status: 'pending',
        // eslint-disable-next-line global-require
        avatar: require('@/assets/images/avatars/3.png'),
      },
      maxImages: 5,
      inputImageRenderer: '',
      form: {},
      productImages: [],
      collateralConditions: [],
      selected: '',
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: this.$t('select customer'),
      },
      limit: 10,
      compressOptions: {
        maxWidthOrHeight: 1200,
      },
    }
  },
  computed: {
    ...mapGetters({
      getCustomers: 'app/getCustomers',
      user: 'getUser',
    }),
    paymentAmount() {
      if (this.form.collateralValue && this.form.interestRate) {
        return round(multiply(this.form.collateralValue, divide(this.form.interestRate, 100)))
      }
      return 0
    },
  },
  created() {
    store.dispatch('app/getCustomersFromDb', { user: this.user })
  },
  mounted() {
    this.defaultSettings()

    store.state.collaterals.collateralConditions.forEach(stats => {
      this.collateralConditions.push({
        key: stats,
        title: this.$t(stats),
      })
    })

    const refInputEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      this.userData.avatar = base64
    })
    this.inputImageRenderer = inputImageRenderer
  },
  methods: {
    onSelected(option) {
      this.selected = option.item
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.basicInfo.fullName
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        this.selected = {}
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.getCustomers.filter(item => {
        item.suggest = ''
        let check = false
        if (item.basicInfo.fullName.toLowerCase().indexOf(text) > -1) {
          item.suggest = ''
          check = true
        }
        if (item.basicInfo.idNumber.toLowerCase().indexOf(text.toLowerCase()) > -1) {
          if (item.suggest !== '') item.suggest += ', '
          item.suggest += item.basicInfo.idNumber
          check = true
        }
        if (item.basicInfo.cellPhoneNumber.toLowerCase().indexOf(text.toLowerCase()) > -1) {
          if (item.suggest !== '') item.suggest += ', '
          item.suggest += item.basicInfo.cellPhoneNumber
          check = true
        }
        if (!check) this.selected = {}
        return check
      }).slice(0, this.limit)

      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    defaultSettings() {
      if (this.$route.params.action === 'create') {
        this.form = {
          productName: '',
          productType: '',
          productBrand: '',
          productModel: '',
          productYear: '',
          productCondition: '',
          productAppraisal: '',
          serialNumber: '',
          location: '',
          status: '',
          collateralDate: '',
          foreclosureDate: '',
          loanAmount: '',
          interestRate: '',
          customer: undefined,
          productImages: [],
          otherInformation: '',
        }
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      this.productImages = fileList
    },
    beforeRemove(index, done, fileList) {
      this.productImages = fileList
      done()
    },
    editImage(formData, index, fileList) {
      this.productImages = fileList
    },
    async handleSuibmit() {
      if (this.productImages.length > 0) {
        await this.productImages.forEach(async image => {
          try {
            const data = await this.dataURItoBlob(image.path)
            const compressedFile = await imageCompression(data, this.compressOptions)
            image.blob = compressedFile
          } catch {
          // do something
          }
        })
        this.form.productImages = this.productImages
      }
      store.dispatch('createProduct', this.form)
    },
    dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString
      if (dataURI.split(',')[0].indexOf('base64') >= 0) { byteString = atob(dataURI.split(',')[1]) } else { byteString = unescape(dataURI.split(',')[1]) }

      // separate out the mime component
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to a typed array
      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ia], { type: mimeString })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
